import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { ApiService } from '../api/api.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  /**
   * @param {Router} router
   * @param {AuthenticationService} authenticationService
   */
  constructor(private router: Router, private api: ApiService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.includes('users/auth/refresh')) {
      return next.handle(request);
    }
    if (!request.url.startsWith(`${environment.url}/user/auth`)) {
      if (this.isTokenExpired()) {
        console.log('le test est a ce niveau')
        return this.api.refreshAccesToken().pipe(
          switchMap(() => {
            const headers = new HttpHeaders()
              .append('Authorization', `Bearer ${this.api.getAccessToken()}`);
            const modifiedReq = request.clone({ headers });
            return next.handle(modifiedReq);
          })
        )
      }
      const headers = new HttpHeaders()
        .append('Authorization', `Bearer ${this.api.getAccessToken()}`);
      const modifiedReq = request.clone({ headers });
      return next.handle(modifiedReq).pipe(
        catchError(err => {
          if (err.status == 401) {
            localStorage.clear();
            this.router.navigate(['login']);
          }
          const error = {
            status: err.status,
            message: err.error?.message || err.statusText,
          };
          return throwError(() => error);
        })
      );
    }
    return next.handle(request);
  }

  private getTokenDecode(): any | null {
    const token = this.api.getAccessToken();
    if (!token) return null;
    const base64Payload = token.split('.')[1];
    const payload = this.base64UrlDecode(base64Payload);
    return JSON.parse(payload);
  }

  private isTokenExpired(): boolean {
    const token = this.getTokenDecode();
    if (!token) return false;

    // On regarde si le token est expiré et s'il s'est ecouler moins de 15min apres son expiration, alors on retourne `true`
    return (((token.exp * 1000) <= Date.now()) && ((Date.now() - token.exp * 1000) < (15 * 60 * 1000))) ? true : false;
  }

  private base64UrlDecode(base64Url: string): string {
    base64Url = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    while (base64Url.length % 4) {
      base64Url += '=';
    }
    return atob(base64Url);
  }
}
