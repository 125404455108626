export class User
{
       id?: string;
       name?: string;
       firstName?: string;
       lastName?: string;
       email?: string;
       password?: string;
       phone?: string;
       img?: string;
       address?: string;
       bio?: string;
       createdAt?: string;
       status?: string;
       emailConfirmed?: boolean;
       coverPicture?: string;
       country?: string;
       whatsapp?: string;
       skype?: string;
       websiteLink?: string;
       city?: string;
       authType?: string;
       role?: [];
       userSetting?: [];
       _id?: string;
}
