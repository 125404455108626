import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders
} from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    // On récupére le token de vérification d'email
    const emailToken = localStorage.getItem('email_datas');

    // Si un token existe, cloner la requête et ajouter le header d'autorisation
    if (emailToken) {
      const modifiedRequest = request.clone({
        headers: request.headers.set('Authorization', `Bearer ${emailToken}`)
      });

      return next.handle(modifiedRequest);
    }

    // Si pas de token, passer la requête originale
    return next.handle(request);
  }
}